import React from 'react';
import { graphql } from 'gatsby';
import { render } from 'datocms-structured-text-to-plain-text';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Typography from '../../../primitives/typography';
import { breakpoints } from '../../../primitives/tokens';
import DatoAsset from '../../../primitives/asset';

export type TextColumnSetProps = Queries.TextColumnSetPropsFragment;

const TextColumnSet = ({
    richTitle,
    richText,
    columns,
    columnCount,
    canGrid,
    titlingAsParagraphs,
    largeIcons
}: TextColumnSetProps): JSX.Element => (
    <>
        {(render(richText) || render(richTitle)) && (
            <Container css={{
                marginTop: '80px',
                marginBottom: '80px',
                [breakpoints.tb]: {
                    marginTop: '54px',
                    marginBottom: '54px',
                },
                [breakpoints.mb]: {
                    marginBottom: '39px',
                    marginTop: '39px',
                },
            }}
            >
                <Row>
                    <Col breakpoints={{
                        dt: { span: titlingAsParagraphs ? 1 : 0 }, tb: { span: 0 },
                    }}
                    />
                    <Col breakpoints={{
                        dt: { span: titlingAsParagraphs ? 10 : 12 }, tb: { span: 12 },
                    }}
                    >
                        <Typography
                            fontSize={{
                                dt: 4053,
                                tb: 3234,
                                mb: 2429,
                            }}
                            override
                            fontWeight="light"
                            css={{
                                textAlign: titlingAsParagraphs ? 'left' : 'center',
                            }}
                        >
                            <CustomStructuredTextRenderer data={richTitle} />
                        </Typography>
                        {render(richText) && (
                            <Typography
                                fontSize={{
                                    dt: 1822,
                                    tb: 1627,
                                    mb: 1322,
                                }}
                                css={{
                                    paddingTop: '12px',
                                    textAlign: titlingAsParagraphs ? 'left' : 'center',
                                    opacity: 0.6,
                                    maxWidth: titlingAsParagraphs ? 'unset' : '800px',
                                    margin: 'auto',
                                }}
                                override
                            >
                                <CustomStructuredTextRenderer data={richText} />
                            </Typography>
                        )}
                    </Col>
                </Row>
            </Container>
        )}
        {columns.length > 0 && (
            <Container css={{
                marginBottom: '120px',
                [breakpoints.tb]: {
                    marginBottom: '80px'
                },
                [breakpoints.mb]: {
                    marginBottom: '60px'
                }
            }}
            >
                <Row css={{
                    rowGap: '32px',
                    [breakpoints.tb]: {
                        rowGap: '24px',
                    },
                    [breakpoints.mb]: {
                        rowGap: '16px',
                    },
                }}
                >
                    {columns.map((column, idx) => (
                        <Col breakpoints={{
                            dt: {
                                span: (() => {
                                    if (titlingAsParagraphs && columnCount === '6') {
                                        return 5;
                                    }
                                    return Number(columnCount);
                                })(),
                                start: (() => {
                                    if (titlingAsParagraphs) {
                                        if (idx % 2 === 0) {
                                            return 1;
                                        }
                                        return 6;
                                    }
                                    return undefined;
                                })(),
                            },
                            tb: {
                                span: Number(columnCount),
                            },
                            mb: { span: canGrid ? 2 : 4, start: canGrid ? (idx % 2) * 2 : 0 },
                        }}
                        >
                            <div css={{
                                textAlign: 'center',
                            }}
                            >
                                {column.icon && (
                                    <DatoAsset
                                        css={{
                                            maxWidth: largeIcons ? '140px' : '80px',
                                            margin: 'auto',
                                            marginBottom: '32px',
                                            [breakpoints.tb]: {
                                                maxWidth: largeIcons ? '120px' : '80px',
                                            },
                                            [breakpoints.mb]: {
                                                maxWidth: largeIcons ? '110px' : '80px',
                                                marginBottom: '16px',
                                            },
                                        }}
                                        objectFit="contain"
                                        asset={column.icon}
                                    />
                                )}
                                <Typography
                                    fontSize={{
                                        dt: 2429,
                                        tb: 1822,
                                        mb: 1627,
                                    }}
                                    color={(() => {
                                        if (titlingAsParagraphs) {
                                            return  'primaryDarkPurple'
                                        }
                                        if (!column.text) {
                                            return 'primaryDarkPurple'
                                        }
                                        return  'primaryOrange'
                                    })()}
                                    fontWeight="medium"
                                    override
                                    css={{
                                        textAlign: titlingAsParagraphs ? 'left' : 'center',
                                    }}
                                >
                                    {column.title}
                                </Typography>
                                {column.text && (
                                    <Typography
                                        fontSize={{
                                            dt: 1627,
                                            tb: 1420,
                                            mb: 1226,
                                        }}
                                        css={{
                                            marginTop: '16px',
                                            textAlign: titlingAsParagraphs ? 'left' : 'center',
                                            opacity: 0.8,
                                        }}
                                        override
                                    >
                                        {column.text}
                                    </Typography>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        )}
    </>
);

export default TextColumnSet;

export const query = graphql`
    fragment TextColumnSetProps on DatoCmsTextColumnSet {
        titlingAsParagraphs
        largeIcons
        richTitle {
            blocks
            links
            value
        }
        richText {
            blocks
            links
            value
        }
        columnCount
        canGrid
        columns {
            title
            text
            icon {
                ...DatoCmsInternalAsset
            }
        }
    }
`;
